export const breakpoints = {
  small: 480,
  medium: 640,
  tablet: 768,
  laptop: 1024,
  full: 1280,
}

export const colors = {
  blue100: '#3366CC',
  blue75: '#398FE6',
  blue50: '#40BFFF',
  blue5: '#F2F9FF',
  green100: '#00B3B3',
  green75: '#00BF9F',
  green50: '#29CC96',
  green5: '#EDFAF8',
  grey80: 'rgba(0, 0, 0, 0.8)',
  grey60: 'rgba(0, 0, 0, 0.6)',
  grey50: 'rgba(0, 0, 0, 0.5)',
  grey40: 'rgba(0, 0, 0, 0.4)',
  grey30: 'rgba(0, 0, 0, 0.3)',
  grey10: 'rgba(0, 0, 0, 0.1)',
  shadow: 'rgba(57, 143, 230, 0.25)',
}

export const fonts = {
  body: ['Quicksand', 'sans-serif'],
  heading: ['Sansita', 'serif'],
}

export const fontSizes = {
  small: '0.9em',
  base: {
    desktop: '16px',
    mobile: '18px',
  },
  h1: {
    desktop: '3.333rem',
    mobile: '2rem',
  },
  h2: {
    desktop: '2rem',
    mobile: '1.5em',
  },
  h3: {
    desktop: '1.667rem',
    mobile: '1.25em',
  },
  h4: {
    desktop: '1.333rem',
    mobile: '1.125rem',
  },
  h5: {
    desktop: '1.333rem',
    mobile: '1.125rem',
  },
  h6: {
    desktop: '1rem',
    mobile: '1rem',
  },
}
