import { Global, css } from '@emotion/react'

import Helmet from 'react-helmet'
import React from 'react'

export default (props) => (
  <>
    <Helmet
      title="Zwemmen met walvissen in Tonga en Nieuw-Zeeland - Voja travel"
      meta={[
        {
          name: 'description',
          content:
            'Zwemmen met walvissen in een exotisch paradijs. Een 28 daagse reis naar Tonga en Nieuw-Zeeland door Voja travel.',
        },
      ]}
    >
      <html lang="nl" class={props.noScroll ? 'no-scroll' : ''} />
    </Helmet>
    <Global
      styles={css`
        html {
          -webkit-font-smoothing: antialiased;
          -ms-overflow-style: -ms-autohiding-scrollbar;

          &.no-scroll {
            overflow-y: hidden;

            body {
              overflow-y: hidden;
            }
          }
        }
      `}
    />
    {props.children}
  </>
)
