import { breakpoints, colors } from '../variables'

import Arrow from './arrow'
import React from 'react'

export default ({
  external = true,
  arrow = true,
  children,
  className,
  color = 'blue',
  link,
  type = 'link',
}) => {
  const css = [
    `
    background-color: ${colors.blue75};
    background-image: ${
      color === 'green'
        ? `linear-gradient(30deg, #009999, ${colors.green50})`
        : `linear-gradient(30deg, #303CBF, ${colors.blue50})`
    };
    background-position: 100% 0;
    background-size: 200%;
    border-radius: 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    padding: 10px 2rem;
    text-decoration: none;
    transition: background-position 0.2s ease-out, box-shadow 0.2s ease-out;
    will-change: background-position, box-shadow;

    @media(min-width: ${breakpoints.tablet}px) {
      font-size: 16px;
      padding: 15px 2.5rem;
    }

    &:hover,
    &:focus {
      background-position: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      outline: none;
    }

  `,
    className,
  ]

  if (type === 'link') {
    return (
      <a
        css={css}
        href={link}
        rel={external ? 'noopener noreferrer' : ''}
        target={external ? '_blank' : '_self'}
      >
        {children}
        {arrow && <Arrow direction={arrow} />}
      </a>
    )
  } else if (type === 'submit') {
    return (
      <button
        css={[
          {
            border: 0,
          },
          css,
        ]}
        type="submit"
      >
        {children}
        {arrow && <Arrow direction={arrow} />}
      </button>
    )
  }
}
