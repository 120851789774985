import React from 'react'
import { colors } from '../variables'
import { css } from '@emotion/react'

export default ({ position }) => (
  <svg
    css={css`
      bottom: ${position !== 'hero' ? 'auto' : '-1px'};
      fill: ${position === 'bottom' ? colors.blue5 : 'white'};
      height: ${(3 / 16) * 100}vw;
      left: 0;
      pointer-events: none;
      position: ${position === 'bottom' ? 'relative' : 'absolute'};
      top: ${position === 'hero' ? 'auto' : '-1px'};
      width: 100vw;
      z-index: ${position === 'bottom' ? 1 : 0};
    `}
    viewBox="0 0 1600 300"
    xmlns="http://www.w3.org/2000/svg"
  >
    {position === 'bottom' && <path d="M0 0h1600v280C980 380 620-80 0 20V0z" />}
    {position === 'hero' && <path d="M1600 300V0C980 240 620 60 0 300h1600z" />}
    {position === 'top' && <path d="M0 20V0h1600v300C1300 20 800 20 0 20z" />}
  </svg>
)
