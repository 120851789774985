import { breakpoints, colors } from '../variables'

import React from 'react'

export default ({ green = false, small = false, className }) => (
  <div
    css={[
      `
      background-color: ${green ? colors.green75 : colors.blue75};
      height: ${small ? '2px' : '3px'};
      margin-bottom: ${small ? '1.5rem' : '2rem'};
      width: ${small ? '20px' : '40px'};

      @media(min-width: ${breakpoints.tablet}px) {
        height: ${small ? '3px' : '4px'};
        width: ${small ? '40px' : '60px'};
      }
    `,
      className,
    ]}
  />
)
